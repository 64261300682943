.participants {
  padding: 12px 0;
}

.participant {
  margin-bottom: 8px;
}

.member {
  color: var(--gray);
  margin-left: 5px;
  font-size: 14px;
}

.details {
  margin: 20px 0;
  border: 1px solid var(--sec-01);
  border-radius: 5px;
  min-height: 500px;
}

.sidebar {
  border: none;
}

.main {
  padding: 20px 0;
}

.label {
  color: var(--black);
  font-family: var(--font-bold);
  font-size: 16px;
}

.value {
  font-size: 16px;
  font-family: var(--font-reg);
  margin: 10px 0;
}

.na {
  font-size: 16px;
  font-family: var(--font-reg);
  margin: 10px 0;
  color: var(--gray);
}

.btns {
  margin-left: 5px;
}

.title {
  display: flex;
  align-items: center;
}

.title:hover .pencil {
  visibility: visible;
}

.title .input {
  width: 350px;
  margin-top: 4px;
  margin-bottom: 3px;
}

.title .input input {
  letter-spacing: normal;
}

.pencil {
  transition: all 200ms;
  visibility: hidden;
  width: 14px;
  height: 14px;
  color: var(--pri-02);
  margin-left: 10px;
  cursor: pointer;
}

.textbox-value {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  width: 700px;
}

.textbox-value:hover .pencil {
  visibility: visible;
}