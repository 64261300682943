.root {
  overflow-y: unset;
}

.dropdown {
  width: 150px;
  margin: 40px auto;
}

.btns {
  text-align: center;
}

.btn {}