.name {
  display: flex;
  align-items: center;

  overflow: hidden;
}

.link {
  overflow: hidden;
  cursor: pointer;
}

.link:hover {
  color: var(--hyperlink);
  text-decoration: underline;
}

.trunc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  margin-right: 10px;
  flex-shrink: 0;
}

.file-name {
  display: flex;
  align-items: center;
  gap: 5px;
}

.transcript-status {
  margin-left: 8px;
}

.transcript {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.checkbox {
  color: #737373;
}

.expired {
  color: var(--gray-d);
}

.cell-tags {
  max-width: 200px;
  flex-wrap: nowrap;
}

.root {  
  display: flex;  
  align-items: center;  
  gap: 5px;  
}  
  
.checkbox-label {
  height: 18px;
  width: 18px;
}
